import { DateTime } from "luxon";
import { ONBOARDING_V2_COMPANIES } from "./constants";
import { IS_PRODUCTION } from "./environment";

export const formatDate = (start: string, end?: string, year = false): string => {
  if (!start) return "";

  const startDt = DateTime.fromISO(start);
  const daysTillStart = Math.abs(startDt.diffNow("days").days);
  const showStartYear = daysTillStart > 180 || year ? ", yyyy" : "";
  let startFormat = "MMM d";

  if (!end) {
    return startDt.toFormat(startFormat + showStartYear);
  }

  const endDt = DateTime.fromISO(end);
  const daysTillEnd = Math.abs(endDt.diffNow("days").days);
  let endFormat = daysTillEnd > 180 || showStartYear ? ", yyyy" : "";

  if (startDt.year === endDt.year) {
    endFormat = (startDt.month === endDt.month ? "d" : "MMM d") + endFormat;
  } else {
    startFormat = endFormat = "MMM d, yyyy";
  }
  return startDt.toFormat(startFormat) + " - " + endDt.toFormat(endFormat);
};

export const addressToString = (addressObj: $TSFixMe, oneLiner?: boolean): string => {
  return (
    (addressObj.postal_name || "").trim() +
    (oneLiner ? ", " : "\n") +
    (addressObj.line1 || "").trim() +
    (oneLiner ? " " : "\n") +
    (addressObj.line2 ? (addressObj.line2 || "").trim() + (oneLiner ? " " : "\n") : "") +
    (
      (addressObj.city || "") +
      ", " +
      (addressObj.state?.value || addressObj.state || "") +
      " " +
      (addressObj.postal_code || "")
    ).trim()
  ).trim();
};

export const truncate = (str: string, n: number): string => {
  if (str.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
};

export const capitalize = (s: string): string => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// Funky little function to get the dates between (inclusive) a start and end date
export const datesBetween = (
  start: string | number | Date,
  end: Date,
  includeWeekends: boolean
): DateTime[] => {
  const arr: DateTime[] = [];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    if (!includeWeekends && (dt.getDay() === 0 || dt.getDay() === 6)) {
      continue;
    }

    arr.push(DateTime.fromJSDate(new Date(dt)));
  }
  return arr;
};

export const openBlobThisTab = (blob: Blob): void => {
  const url = URL.createObjectURL(blob);
  window.location.replace(url);
  // DO NOT REVOKE THE URL OR ELSE THIS BREAKS FOR SAFARI
};

/**
 * Returns true if the company has access to the new onboarding checklist
 * or if the app is not in production
 * @param companyId
 * @returns
 */
export const hasOnboardingV2AccessInTP = (companyId: string): boolean => {
  return ONBOARDING_V2_COMPANIES.includes(companyId) || !IS_PRODUCTION;
};

export const HAYS_ELECTRICAL_COMPANY_ID = "652d926eb2ad0a006e6486de";
export const DEMO_COMPANY_ID = "61228e16f0388a0093a6ba44";
export const MITER_COMPANY_ID = "610313a27dc240004465007b";
export const AMERICAN_ROOFING_METAL_COMPANY_IDS = [
  "642701b59b8fc70064e40f7d",
  "6427026d9b8fc70064e42149",
  "642702d69b8fc70064e42456",
];
export const KAUFMAN_LYNN_COMPANY_ID = "64b58d5b91cef4006790cf85";
export const ONYX_COMPANY_ID = "659ad53fe8e8f1006d4ba8ab";
export const PERIMETER_SECURITY_PARTNERS_COMPANY_ID = "65a861c8f3cf1500726d53bd";
export const JAMAR_COMPANY_ID = "649a034052701100673ddb7f";
export const VITAL_MECHANICAL_SERVICE_COMPANY_ID = "6661d98c2acb6e54102e39b9";
export const CARRIERE_STUMM_COMPANY_ID = "65a54140dbe91e0072feaf4e";
export const LAKESHORE_COMPANY_ID = "64cd5cc11411710069b72085";
export const MARCON_COMPANY_ID = "64c9071aa55f360069fd986e";
export const MARKSMEN_GC_COMPANY_ID = "6655f3b8a72b1c98024c25ec";

// flow services
const FLOW_SERVICES_MANAGEMENT_COMPANY_ID = "66e832d5c4c4872d5b1a719d";
const CORTEZ_COMPANY_ID = "67290b68961ff8b201c8066a";
const DYNAMIC_AIR_COMPANY_ID = "6707eec420317c1b7b73fcbd";
const PERFECTION_HEATING_COMPANY_ID = "66be674b7568c3c7db1569c1";
const R_BROOKS_MECHANICAL_COMPANY_ID = "66f16de2d1de78325215f9ba";
const ALTSTADT_COMPANY_ID = "671a6d1217b5ef2abe2cf313";

export const FLOW_SERVICES_COMPANY_IDS = [
  FLOW_SERVICES_MANAGEMENT_COMPANY_ID,
  PERFECTION_HEATING_COMPANY_ID,
  R_BROOKS_MECHANICAL_COMPANY_ID,
  CORTEZ_COMPANY_ID,
  DYNAMIC_AIR_COMPANY_ID,
  ALTSTADT_COMPANY_ID,
];

export const DEBLOIS_ELECTRIC_COMPANY_ID = "668bf96f64ac8fea1437c184";
export const UNITED_CONSTRUCTION_COMPANY_ID = "652db00db2ad0a006e6dd8f1";
export const CVE_DEMO_COMPANY_ID = "65a0058547b3a000711318fe";
export const CVE_FRESNO_COMPANY_ID = "65a004fd47b3a0007112dbd4";
export const CVE_NORTH_BAY_COMPANY_ID = "65a0732fb5c12000724a0807";
export const PARAGON_COMPANY_ID = "64beb34891f7770066dc5dfd";
export const NEW_HORIZONS_TELECOM_COMPANY_ID = "66bbc36da74c01fc15b23288";
export const NFL_LTD_COMPANY_ID = "66bbc388a74c01fc15b2501d";

// WL French entities
export const WL_FRENCH_JR_TRUCKING_COMPANY_ID = "665ddc333fdb4a8f7f43191d";
export const WL_FRENCH_EXCAVATING_COMPANY_ID = "665ddbed3fdb4a8f7f42ea25";
export const JL_FRENCH_TRANSPORTATION_COMPANY_ID = "665ddc793fdb4a8f7f434f9d";

export const MARATHON_ELECTRICAL_ID = "6491e90434bdbb0068e673ef";
export const STANSELL_COMPANY_ID = "66797582b11eb6fd956dc5d6";

export const KENT_POWER_COMPANY_IDS = ["66b3d069f2e91e476ab40e13", "66be61a77568c3c7db101d70"];

export const STILES_COMPANY_ID = "64db07f6d6692c006be0c92a";
export const QOVO_COMPANY_ID = "631916a23154db007ea839a4";
export const REVIM_COMPANY_ID = "65d4d3cf92b6750071b6df33";
export const DIXIE_OVERLAND_COMPANY_ID = "66461b4060366d69a40dc095";
export const PRAETORIAN_POWER_PROTECTION_COMPANY_ID = "65faf82475bc81ba5e42b988";

export const ONBOARDING_V2_COMPANIES = [
  DEMO_COMPANY_ID,
  MITER_COMPANY_ID,
  ...AMERICAN_ROOFING_METAL_COMPANY_IDS,
  KAUFMAN_LYNN_COMPANY_ID,
  ONYX_COMPANY_ID,
  PERIMETER_SECURITY_PARTNERS_COMPANY_ID,
  JAMAR_COMPANY_ID,
  VITAL_MECHANICAL_SERVICE_COMPANY_ID,
  CARRIERE_STUMM_COMPANY_ID,
  LAKESHORE_COMPANY_ID,
  MARCON_COMPANY_ID,
  MARKSMEN_GC_COMPANY_ID,
  ...FLOW_SERVICES_COMPANY_IDS,
  DEBLOIS_ELECTRIC_COMPANY_ID,
  UNITED_CONSTRUCTION_COMPANY_ID,
  CVE_DEMO_COMPANY_ID,
  CVE_FRESNO_COMPANY_ID,
  CVE_NORTH_BAY_COMPANY_ID,
  PARAGON_COMPANY_ID,
  NEW_HORIZONS_TELECOM_COMPANY_ID,
  NFL_LTD_COMPANY_ID,
  WL_FRENCH_JR_TRUCKING_COMPANY_ID,
  WL_FRENCH_EXCAVATING_COMPANY_ID,
  JL_FRENCH_TRANSPORTATION_COMPANY_ID,
  STANSELL_COMPANY_ID,
  ...KENT_POWER_COMPANY_IDS,
  STILES_COMPANY_ID,
  QOVO_COMPANY_ID,
  REVIM_COMPANY_ID,
  DIXIE_OVERLAND_COMPANY_ID,
  PRAETORIAN_POWER_PROTECTION_COMPANY_ID,
];

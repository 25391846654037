import { AggregatedTeamMember } from "dashboard/miter";
import React, { useState } from "react";
import { TeamPortalUser } from "team-portal/utils/miter";
import { Button, Formblock } from "ui";
import { useTranslation } from "react-i18next";
import { UpdatePaymentMethodAndWithholdingsModal } from "./UpdatePaymentMethodAndWithholdingsModal";
import { CheckCircle, WarningCircle } from "phosphor-react";
import { CheckEmployee } from "../../../backend/utils/check/check-types";
import type { TeamMemberAbilities, TeamMemberParams } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

type Props = {
  teamMember: AggregatedTeamMember | TeamPortalUser;
  onUpdate?: () => void;
  teamAbilities?: TeamMemberAbilities;
};
export const InlinePaymentMethodAndWithholdings: React.FC<Props> = ({
  teamMember,
  onUpdate,
  teamAbilities,
}) => {
  const { t } = useTranslation<$TSFixMe>();

  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);

  const remainingOnboardSteps = teamMember?.check_tm?.onboard?.remaining_steps || [];

  const withholdingsNeedAttention =
    remainingOnboardSteps.some((s) => s === "withholdings" || s === "ssn") ||
    (teamMember?.employment_type === "employee" && !(teamMember?.check_tm as CheckEmployee)?.residence);

  const paymentMethodLookup = {
    direct_deposit: t("Direct deposit"),
    manual: t("Paper check"),
  };

  return (
    <div>
      <div className="flex space-between">
        <h2>
          {t("Payment method") +
            (teamMember?.employment_type === "employee" ? " " + t("& withholdings") : "")}
        </h2>
        {(!teamAbilities || teamAbilities.can("update_pay_info", teamMember as TeamMemberParams)) && (
          <Button text={t("Edit")} onClick={() => setOpenPaymentMethodModal(true)} />
        )}
      </div>
      <div>
        <Formblock
          type="text"
          locked={true}
          defaultValue={
            paymentMethodLookup[teamMember.check_tm?.payment_method_preference ?? ""] || t("Not set")
          }
          label={t("Payment method")}
        />
        {teamMember.employment_type === "employee" ? (
          <Formblock
            type="text"
            locked={true}
            // @ts-expect-error check_tm will be CheckEmployee
            defaultValue={teamMember.check_tm?.w2_electronic_consent_provided ? "Yes" : "No"}
            label={t("Paperless W-2s")}
          />
        ) : (
          <Formblock
            type="text"
            locked={true}
            // @ts-expect-error check_tm will be CheckContractor
            defaultValue={teamMember.check_tm["1099_nec_electronic_consent_provided"] ? "Yes" : "No"}
            label={t("Paperless 1099s")}
          />
        )}
        {teamMember.employment_type === "employee" && (
          <>
            <div style={{ height: 10 }}></div>
            <Formblock type="text" locked={true} label={t("Withholdings")}>
              {withholdingsNeedAttention ? (
                <WarningCircle weight="fill" color="#b60d0c" />
              ) : (
                <CheckCircle weight="fill" color="#00b300" />
              )}
            </Formblock>
          </>
        )}
      </div>
      {openPaymentMethodModal && (
        <UpdatePaymentMethodAndWithholdingsModal
          teamMember={teamMember}
          onClose={() => {
            setOpenPaymentMethodModal(false);
            if (onUpdate) onUpdate();
          }}
        />
      )}
    </div>
  );
};

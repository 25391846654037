import AppContext from "team-portal/contexts/app-context";

import { DateTime } from "luxon";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { BasicModal, Notifier } from "ui";
import ReactCodeInput from "react-code-input";
import styles from "./ReverifyModal.module.css";
import { MiterAPI } from "team-portal/utils/miter";

const ReverifyModal: React.FC = () => {
  const { setReverifyUser, onReverifyUser, setOnReverifyUser, user, setUser } = useContext(AppContext);

  const [firstLoad, setFirstLoad] = useState(true);
  const [authMethodId, setAuthMethodId] = useState<string | undefined>();
  const [authCode, setAuthCode] = useState<string | undefined>();
  const [sendingCode, setSendingCode] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);

  const isReverified = (() => {
    const reverifiedAt = DateTime.fromSeconds(user?.reverified_at || 0);
    const now = DateTime.now();

    return now.diff(reverifiedAt).as("hours") < 24;
  })();

  const sendAuthCode = useCallback(async () => {
    if (!user) return;
    if (!user?.phone) {
      Notifier.warning("You must add a phone number to your account to verify it.");
      return;
    }

    setSendingCode(true);

    try {
      const res = await MiterAPI.users.sendAuthCode(user.phone);
      if (res.error) throw Error(res.error);

      setAuthMethodId(res.method_id);
      Notifier.success("A verification code has been sent to your phone.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error("Unable to send auth code:", e);
    }

    setFirstLoad(false);
    setSendingCode(false);
  }, [user, setSendingCode, setAuthMethodId, setFirstLoad]);

  useEffect(() => {
    if (firstLoad) {
      if (isReverified) {
        setReverifyUser(false);
        if (typeof onReverifyUser === "function") {
          onReverifyUser();
        }
      } else {
        sendAuthCode();
      }
      setFirstLoad(false);
    }
  }, [firstLoad, user, isReverified, onReverifyUser, sendAuthCode, setReverifyUser]);

  const hideModal = () => {
    setReverifyUser(false);
  };

  const verifyAuthCode = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!authMethodId || !user || !authCode) return;

    setVerifyingCode(true);
    try {
      const res = await MiterAPI.users.reverify(authCode, authMethodId);
      if (res.error) throw Error(res.error);

      setUser(res);
      if (typeof onReverifyUser === "function") {
        onReverifyUser();
      }
      setOnReverifyUser(() => {});
      Notifier.success("Your account has been verified.");
    } catch (e: unknown) {
      Notifier.error(e instanceof Error ? e.message : "An error occurred");
      console.error("Unable to verify auth code:", e);
    }
    setVerifyingCode(false);
  };

  if (isReverified) {
    return <></>;
  }

  return (
    <>
      <BasicModal
        headerText={"Secondary verification"}
        subheaderText={"Please enter the 6 digit auth code we sent to your phone number."}
        button1Text={"Resend code"}
        button1Action={sendAuthCode}
        button1Loading={!firstLoad && sendingCode}
        button2Text={"Submit"}
        button2Action={verifyAuthCode}
        button2Loading={verifyingCode}
        wrapperClassName={styles["verify-modal-wrapper"]}
        showCloseX={true}
        onHide={hideModal}
        bloackClickaway={true}
      >
        <div className={styles["verify-form"]}>
          {!verifyingCode && (
            <ReactCodeInput
              className={styles["react-code"]}
              inputMode="numeric"
              name="authCode"
              type="number"
              fields={6}
              onChange={(val) => setAuthCode(val)}
            />
          )}
        </div>
      </BasicModal>
    </>
  );
};

export default ReverifyModal;

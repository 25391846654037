import React, { ReactElement, ReactNode } from "react";

import Label from "./Label";
import Input from "./Input";
import { addressString } from "dashboard/utils/utils";

import _ from "lodash";
import { DateTime } from "luxon";
import { FilePickerFile, FilePickerFiles } from "./FilePicker";
import { Button } from "../button";
import { StylesConfig } from "react-select";
import { Option } from "ui/form/Input";

// Controlled input
// <input value={value} onChange={onChange}/>

// Uncontrolled input
// <input onChange={onChange}/>

export type FormblockProps = React.ComponentProps<typeof Input> &
  Partial<React.ComponentProps<typeof Label>> & {
    defaultString?: string | null | ReactElement;
    editing?: boolean;
    locked?: boolean;
    showPostalName?: boolean;
    underlineTooltip?: boolean;
    children?: React.ReactElement;
    topOfInput?: boolean;
    labelStyle?: React.CSSProperties;
    sublabelStyle?: React.CSSProperties;
    sideBySideInput?: boolean;
    style?: $TSFixMe;
    inputProps?: $TSFixMe;
    noOptionsMessage?: string;
    defaultStringClassname?: string;
    onLabelClick?: (e: $TSFixMe) => void;
    onClick?: (e: $TSFixMe) => void;
    labelButtonText?: string;
    labelButtonClick?: (e: $TSFixMe) => void;
    removeAddressLineBreak?: boolean;
    onDisplayClick?: (e: $TSFixMe) => void;
    hidden?: boolean;
    showPreviewModal?: boolean;
    selectStyles?: StylesConfig<Option<string>, boolean>;
    formblockClassName?: string;
  };

const Formblock = (props: FormblockProps): React.ReactElement => {
  const {
    defaultString,
    defaultValue,
    customMultiselectDefaultValue,
    type,
    className,
    editing,
    labelStyle,
    sublabelStyle,
    style,
    locked,
    sideBySideInput,
    defaultStringClassname,
    onLabelClick,
    labelButtonText,
    labelButtonClick,
    removeAddressLineBreak,
    onDisplayClick,
    hidden,
    showPreviewModal,
    // formblockClassName is used to add a custom class to ONLY the formblock wrapper, and not the label or input
    formblockClassName,
  } = props;

  if (hidden) return <></>;

  const readOnlyValue = (): ReactElement | ReactNode | string | undefined | null => {
    if (props.type === "select" || props.type === "radio") {
      const options = props.options as {
        value: string | undefined | number | null | boolean;
        label: string | ReactNode;
      }[];
      const option = options.find((option) => _.isEqual(option.value, defaultValue));

      if (props.link) {
        return option && option.value ? (
          <a
            className={"purple-link"}
            href={props.link.replace("{{}}", option.value.toString())}
            target="_blank"
            rel="noreferrer"
          >
            {option.label}
          </a>
        ) : (
          defaultString || "-"
        );
      }
      return option ? option.label : defaultString || "-";
    }
    if (type === "datetime") {
      return defaultValue && typeof defaultValue !== "string"
        ? defaultString
          ? defaultString
          : props.dateOnly
          ? props.customFormat
            ? (defaultValue as DateTime).toFormat(props.customFormat)
            : (defaultValue as DateTime).toFormat("EEE, MMM d, yyyy")
          : (defaultValue as DateTime)
              .setZone(props.timezone)
              .toFormat(props.customFormat || "MMM d, yyyy 'at' h:mm a ZZZZ")
        : defaultString;
    }

    if (type === "address") {
      const addressStr = addressString(defaultValue, !removeAddressLineBreak);

      if (props.link) {
        return defaultValue ? (
          <a
            className={"purple-link"}
            href={"https://google.com/maps/search/" + addressString(defaultValue, !removeAddressLineBreak)}
            target="_blank"
            rel="noreferrer"
          >
            {addressStr?.length ? addressStr : "-"}
          </a>
        ) : (
          "-"
        );
      }

      return addressStr?.length ? addressStr : "-";
    }

    if (type === "paragraph") {
      if (!defaultValue) return "-";
    }

    if (type === "file") {
      return <FilePickerFiles files={defaultValue as FilePickerFile[]} showPreviewModal={showPreviewModal} />;
    }

    if (type === "color") {
      if (defaultValue) {
        return (
          <div
            style={{
              backgroundColor: typeof defaultValue === "string" ? defaultValue : "",
              width: 24,
              height: 24,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: -1,
            }}
          ></div>
        );
      } else {
        return "-";
      }
    }

    if (type === "multiselect") {
      const options = props.options as {
        value: string | number;
        label: string | ReactNode;
      }[];
      if (customMultiselectDefaultValue) {
        return customMultiselectDefaultValue?.map((option) => option.label).join(", ") || "-";
      } else if (Array.isArray(defaultValue) && defaultValue?.length) {
        const selectedOptions = options.filter((option) => defaultValue.includes(option.value));
        if (selectedOptions.length) {
          return selectedOptions.map((option) => option.label).join(", ");
        } else {
          return defaultValue.join(", ");
        }
      } else {
        return "-";
      }
    }

    return defaultString || (defaultValue as string);
  };

  const renderLabel = () => {
    if (!props.label) return;

    const labelElement = (
      <Label
        label={props.label}
        labelStyle={labelStyle}
        labelInfo={props.labelInfo}
        tooltipPlace={props.tooltipPlace}
        underlineTooltip={props.underlineTooltip}
        sublabel={props.sublabel}
        className={props.className}
        alert={props.alert}
        onClick={onLabelClick}
        sublabelStyle={sublabelStyle}
      />
    );

    if (labelButtonClick && labelButtonText) {
      return (
        <div className="flex space-between width-100">
          {labelElement}
          <Button
            className={"no-margin button-text purple-link label-wrapper modal"}
            onClick={labelButtonClick}
            style={{ marginRight: 0 }}
          >
            {labelButtonText}
          </Button>
        </div>
      );
    } else {
      return labelElement;
    }
  };

  const locked_class = locked ? " locked " : "";
  const custom_class = className ? " " + className + " " : "";
  const formblock_class = formblockClassName ? " " + formblockClassName + " " : "";
  return (
    <div className={" formblock-wrapper " + locked_class + custom_class + formblock_class} style={style}>
      {renderLabel()}
      {editing && !locked && !sideBySideInput && <Input {...props} />}
      {editing && !locked && sideBySideInput && (
        <div className="flex">
          <Input {...props} />
          {props.children}
        </div>
      )}
      {!editing && props.children && <div className="input-wrapper">{props.children}</div>}
      {!editing && !props.children && (
        <div onClick={onDisplayClick} className={"defaultString input-wrapper " + defaultStringClassname}>
          {readOnlyValue()}
        </div>
      )}
    </div>
  );
};

export default Formblock;

import React, { useMemo } from "react";
import "./Toggler.css";
import { Warning } from "phosphor-react";
import { Badge } from "../badge";

export type TogglerConfigItem = {
  label: string | React.ReactElement;
  path: string;
  alert?: boolean;
  count?: number;
  hide?: boolean;
};

export type TogglerProps<T extends string = string> = {
  path?: T;
  active: string | null | undefined;
  secondary?: boolean;
  toggle: (path: string) => void;
  modal?: boolean;
  config: TogglerConfigItem[];
  children?: React.ReactNode;
  className?: string;
  rightHandContent?: string | React.ReactElement;
  type?: "horizontal" | "vertical";
  wrapperStyle?: React.CSSProperties;
};

const Toggler = <T extends string = string>(props: TogglerProps<T>): React.ReactElement => {
  const type = props.type || "horizontal";
  const visibleTabs = useMemo(() => props.config.filter((option) => !option.hide), [props.config]);

  const newHighlight = (option: TogglerConfigItem) => {
    props.toggle(option.path);
  };

  const getWrapperClassName = (option: TogglerConfigItem) => {
    let className =
      props.active === option.path ? "toggle-menu-option-wrapper highlighted" : "toggle-menu-option-wrapper";
    if (props.modal) {
      className = className + " modal";
    }
    if (props.secondary) {
      className = className + " secondary";
    }
    return className;
  };

  const getTextClassName = (option: TogglerConfigItem) => {
    let className =
      props.active === option.path ? "toggle-menu-option-text highlighted" : "toggle-menu-option-text";
    if (props.modal) {
      className = className + " modal";
    }
    return className;
  };

  const getOverallWrapperClassName = () => {
    let className = "";

    if (type === "horizontal") {
      className += "toggle-wrapper ";
    } else {
      className += "toggle-vertical-wrapper ";
    }

    if (props.modal) {
      className += " modal";
    }

    if (props.secondary) {
      className += " secondary";
    }

    className += " " + props.className;

    return className;
  };

  const renderCountBadge = (option: TogglerConfigItem) => {
    if (option.count == null) return;

    return <Badge className="toggler-count-badge" text={option.count + ""} />;
  };

  return (
    <div className={getOverallWrapperClassName()} style={props.wrapperStyle}>
      {visibleTabs.map((option, index) => (
        <div onClick={() => newHighlight(option)} key={index} className={getWrapperClassName(option)}>
          <span className={getTextClassName(option)}>{option.label}</span>
          {renderCountBadge(option)}
          {option.alert && (
            <Warning size={18} color="#f1c232" weight="duotone" style={{ marginLeft: "5px" }} />
          )}
        </div>
      ))}
      <div className={`toggler-right-spacer ${props.secondary ? "secondary" : ""}`}>
        <div className="flex-1"></div>
        {props.children}
        {props?.rightHandContent || null}
      </div>
    </div>
  );
};

export default Toggler;

import React, { useEffect, useState } from "react";
import { Button, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import useWizard from "ui/modal/useWizard";
import { useTranslation } from "react-i18next";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { AggregatedTeamMemberOnboardingTask, FillDocumentOnboardingTask } from "dashboard/miter";
import onboardingV2WizardStyles from "./onboardingChecklistWizardScreens.module.css";
import FillableDocumentWizard from "../fillable-documents/FillableDocumentWizard"; // Import the wizard

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: AggregatedTeamMemberOnboardingTask & FillDocumentOnboardingTask;
  updateOnboardingChecklistTask: (task: AggregatedTeamMemberOnboardingTask) => Promise<void>;
};

export const FillableDocumentWizardScreen: React.FC<Props> = ({
  name,
  teamMember,
  refetchTeamMember,
  onboardingTask,
  updateOnboardingChecklistTask,
}) => {
  const { t } = useTranslation<$TSFixMe>();

  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();

  const documentCompleted = onboardingTask.fill_document?.fill_status === "complete";

  const [showFillableDocumentWizard, setShowFillableDocumentWizard] = useState(false);
  const [fillableDocumentCompleted, setFillableDocumentCompleted] = useState(documentCompleted);
  const [documentLoading, setDocumentLoading] = useState(false);

  useEffect(() => {
    setCanNext(onboardingTask.status === "complete" || documentCompleted);
    setNextButtonText(curIndex + 1 >= screens.length ? t("Save and exit") : t("Save and continue"));
  }, [onboardingTask.status, documentCompleted, curIndex, screens.length, setCanNext, setNextButtonText, t]);

  const onComplete = () => {
    Notifier.success(t("Fillable document completed successfully."));
    setFillableDocumentCompleted(true);
    setShowFillableDocumentWizard(false);
    setCanNext(true);

    // ... any additional completion logic ...
  };

  const onExit = () => {
    setShowFillableDocumentWizard(false);
  };

  const onNext = async () => {
    await refetchTeamMember();
    await updateOnboardingChecklistTask({
      ...onboardingTask,
      status: "complete",
    });
    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <>
        <div className={styles["content"]}>
          <div className={styles["subheader"]}>
            <h2 className={styles["subheader-title"]}>
              {t("Fill out ") + (onboardingTask.fillable_template?.name || t("fillable document"))}
            </h2>
            <p className={styles["subheader-description"]}>
              {t("Please follow the interactive steps to complete your section of the fillable document")}
            </p>
          </div>
          <div className="form-section">
            <Button
              onClick={() => {
                setDocumentLoading(true);
                setShowFillableDocumentWizard(true);
              }}
              text={fillableDocumentCompleted ? t("Edit document") : t("Fill out document")}
              className={`button-2 no-margin ${onboardingV2WizardStyles["large-button"]}`}
              loading={documentLoading}
            />
          </div>
        </div>
        {showFillableDocumentWizard &&
          onboardingTask.file_id &&
          teamMember &&
          teamMember._id &&
          teamMember.user && (
            <FillableDocumentWizard
              teamMember={{
                full_name: teamMember.full_name
                  ? teamMember.full_name
                  : `${teamMember.first_name} ${teamMember.last_name}`,
                _id: teamMember._id,
                title: teamMember.title || "",
              }}
              userId={teamMember.user}
              role={teamMember.role}
              fillableDocumentId={onboardingTask.file_id}
              onComplete={onComplete}
              onExit={onExit}
              isSuperAdmin={false}
              application={"team-portal"}
              setLoading={setDocumentLoading}
            />
          )}
      </>
    </WizardScreen>
  );
};

import React, { ChangeEvent, CSSProperties, ReactElement } from "react";
import Select, { GroupedOptionsType, Styles } from "react-select";
import { Controller, UseFormMethods } from "react-hook-form";
import "./form.css";

import { styles, smallStyles } from "./styles";
import * as vals from "dashboard/utils/validators";

import AddressInput from "./AddressInput";
import DateDropdown from "./DateDropdown";
import PhoneInput from "react-phone-number-input/input";

import _, { get, noop } from "lodash";
import { DateTime } from "luxon";
import { RegisterFn, SetValueFn } from "./types";
import type { Property } from "csstype";
import ControlledInputWithCursorPosition from "./ControlledInputWithCursor";
import { DateRangePicker, ESignatureInput, FormErrors, MonthYearPicker } from ".";
import { DateTimePicker } from "ui/form";
import FilePicker, { FilePickerFile } from "./FilePicker";
import ColorPicker from "./ColorPicker";
import { DateRange } from "./DateRangePicker";
import InputMask from "react-input-mask";
import { validateFilesRequired, validateMinFiles } from "../utils";
import { Address } from "dashboard/miter";
import { GooglePlaceAutocompleteInput } from "./GooglePlaceAutocompleteInput";
import { MonthYearSelection } from "./MonthYearPicker";
import { TextWithTooltip } from "ui/tooltipItems";

/***************************************************************************
 * Input
 *
 * This component generates the different types of input fields that we use
 * in Miter forms. Below is a basic outline of the different field types
 *
 * - text: regular one-line text input
 * - number: regular number input
 * - paragraph: multi line text input using textarea tag
 * - date: a date and time input
 * - checkbox: a checkbox input
 * - select: a dropdown select input powered by react-select
 * - radio: a radio input that follows the same options formta of the select
 * - address: a text input that is meant to be used for an address
 * - date-dropdown: three select inputs that allow the user to select a date
 * - unit: a text input that is meant to be used for a unit ($, %, etc)
 * - append: a text input that has an additional string outside the input
 *   i.e. [input] hrs
 * - percent: a number input that is meant to be used for a percent
 * *************************************************************************/

export type InputType =
  | "text"
  | "number"
  | "paragraph"
  | "date"
  | "datetime"
  | "checkbox"
  | "radio"
  | "time"
  | "select"
  | "date-dropdown"
  | "address"
  | "unit"
  | "dollars-cents"
  | "append"
  | "phone"
  | "percent"
  | "daterange"
  | "monthyear"
  | "file"
  | "color"
  | "ssn"
  | "esignature"
  | "multiselect"
  | "place-autocomplete";

type InputValue = string | number | readonly string[] | null | number[] | string[] | boolean;
export type Option<Value> = { label: string; value: Value; isDisabled?: boolean };
export type ElementOption<Value> = { label: ReactElement; value: Value; isDisabled?: boolean };

export type ValidationRuleset = React.ComponentProps<typeof Controller>["rules"];

type CommonProps = {
  text?: React.ReactNode;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  register?: RegisterFn;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  control?: React.ComponentProps<typeof Controller>["control"];
  errors?: FormErrors;
  height?: Property.Height;
  onChange?: (e) => void;
  form?: UseFormMethods<$TSFixMe>;
  children?: React.ReactElement;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  rules?: { [key: string]: unknown };
  link?: string;
  onLabelClick?: (e) => void;
  onClick?: (e) => void;

  /* Validation rules. */
  val?: ValidationRuleset;
};

type GenericProps<Value = InputValue> = CommonProps & {
  defaultValue?: Value | null;
  customMultiselectDefaultValue?: Option<$TSFixMe>[];
};

type TextProps = GenericProps<string> & {
  type: "text";
  minLength?: number;
  value?: string | undefined;
  maxLength?: number;
  fontFamily?: string;
};
type PasswordProps = GenericProps<string> & {
  type: "password";
  minLength?: number;
  value?: string | undefined;
  maxLength?: number;
};
type TimeProps = GenericProps<string> & {
  type: "time";
  value?: string | undefined;
};
type NumberProps = GenericProps<number> & {
  type: "number";
  min?: number;
  max?: number;
  value?: number | undefined;
  step?: number;
};
type ParagraphProps = GenericProps<string> & {
  type: "paragraph";
  minLength?: number;
  maxLength?: number;
  rows?: number;
  disableResize?: boolean;
  inputStyle?: CSSProperties;
};

type DateTimeProps = GenericProps<DateTime> & {
  type: "datetime";
  min?: DateTime;
  max?: DateTime;
  defaultValue?: DateTime | null;
  onChange?: (dt: DateTime) => void;
  dateOnly?: boolean;
  timeOnly?: boolean;
  sideEffect?: () => void;
  dateFormat?: string;
  customFormat?: string;
  timePlaceholder?: string;
  timezone?: string;
  value?: DateTime | null;
  disableClearable?: boolean;
  isClearable?: boolean;
};
type DateRangeProps = GenericProps<DateRange> & {
  type: "daterange";
  min?: DateTime;
  max?: DateTime;
  value?: DateRange;
  onChange?: (dt: DateRange) => void;
  ignoreToText?: boolean;
  dateOnly?: boolean;
  sideEffect?: () => void;
  debounceMs?: number;
};

type MonthYearProps = GenericProps<DateRange> & {
  type: "monthyear";
  min?: MonthYearSelection;
  max?: MonthYearSelection;
  value?: MonthYearSelection;
  onChange?: (dt: MonthYearSelection) => void;
  ignoreToText?: boolean;
  dateOnly?: boolean;
  sideEffect?: () => void;
  debounceMs?: number;
};

type CheckboxProps = GenericProps<boolean> & {
  type: "checkbox";
  checked?: boolean;
  checkboxStyle?: CSSProperties;
  checkboxWrapperStyle?: CSSProperties;
  textStyle?: CSSProperties;
  tooltipText?: string; // Add this line
  tooltipPlace?: "top" | "right" | "bottom" | "left";
};
type RadioProps = GenericProps<string | number> & {
  type: "radio";
  options: Option<string | number>[] | { label: React.ReactNode; value: string; isDisabled?: boolean }[];
  value?: string | number | undefined;
};
export type SelectProps = GenericProps<string | number | boolean> & {
  type: "select";
  options:
    | Option<string | undefined | number | null | boolean>[]
    | ElementOption<string | undefined | number | null | boolean>[];
  value?: Option<string | number | undefined | null | boolean> | null;
  requiredSelect?: boolean;
  defaultValue?: string | number | null | boolean;
  smallSelect?: boolean;
  selectStyles?: Partial<Styles<Option<string>, boolean>>;
  maxMenuHeight?: number;
  minMenuHeight?: number;
  isOptionDisabled?: (o: $TSFixMe) => boolean;
  noOptionsMessage?: string;
  isClearable?: boolean;
};
export type MultiSelectProps = GenericProps<(string | number)[]> & {
  type: "multiselect";
  options:
    | Option<string | number | null | undefined>[]
    | GroupedOptionsType<$TSFixMe>
    | ElementOption<string | number | null | undefined>[];
  value?: Option<string | number | undefined | $TSFixMe>[] | null;
  requiredSelect?: boolean;
  smallSelect?: boolean;
  maxMenuHeight?: number;
  minMenuHeight?: number;
  isClearable?: boolean;
  selectStyles?: Partial<Styles<Option<string>, boolean>>;
  style?: CSSProperties;
};
// type DateProps = GenericProps<Date | string> & { type: "date" };
type DateDropdownProps = GenericProps<string> & {
  type: "date-dropdown";
  requiredSelect?: boolean;
  year?: number;
};
type AddressProps = GenericProps<Address> & {
  type: "address";
  showPostalName?: boolean;
  notRequiredRegister?: RegisterFn;
  shortStateSelect?: boolean;
  required?: boolean;
  autocomplete?: boolean;
  setValue?: SetValueFn;
  value?: Address | null;
  link?: string;
};
type UnitProps = GenericProps<number | string> & {
  type: "unit";
  value?: string | undefined;
  unit: string;
};

type DollarsCentsProps = GenericProps<number> & {
  type: "dollars-cents";
};
type AppendProps = GenericProps<string | number> & {
  type: "append";
  min?: number;
  max?: number;
  appendText?: React.ReactNode;
  value?: string | number | undefined;
};
type PercentProps = GenericProps<number> & { type: "percent" };

type PhoneProps = GenericProps<string> & { type: "phone" };

type FileProps = GenericProps<FilePickerFile[]> & {
  type: "file";
  multiple?: boolean;
  variant: "dropzone" | "button" | "dropzone-button";
  maxFileSize?: number;
  acceptedFileTypes?: string[];
  customRenderSelectedFiles?: (files: FilePickerFile[] | null) => ReactElement;
  showButton?: boolean;
  maxFilesAllowed?: number;
  compact?: boolean;
  dropzoneLabel?: string;
  readOnly?: boolean;
  hideUploadIcon?: boolean;
  iconSize?: string;
  showPreviewModal?: boolean;
  min?: number;
  showFilePreview?: boolean;
  noMargin?: boolean;
};

type ColorProps = GenericProps<string> & { type: "color" };

type SSNProps = GenericProps<string> & { type: "ssn" };

type ESignatureProps = GenericProps<string> & {
  type: "esignature";
  disclosure?: string;
  agreement?: string;
  agreementAffirmationText?: string;
  onError: (error: string) => void;
};

export type GooglePlaceAutocompleteProps = GenericProps<string> & {
  type: "google-place-autocomplete";
  value?: string;
};

type Props =
  | TextProps
  | PasswordProps
  | NumberProps
  | ParagraphProps
  | CheckboxProps
  | RadioProps
  | SelectProps
  | MultiSelectProps
  | DateTimeProps
  | DateDropdownProps
  | AddressProps
  | UnitProps
  | DollarsCentsProps
  | AppendProps
  | PercentProps
  | TimeProps
  | PhoneProps
  | FileProps
  | DateRangeProps
  | MonthYearProps
  | ColorProps
  | SSNProps
  | ESignatureProps
  | GooglePlaceAutocompleteProps;

const Input = (props: Props): React.ReactElement => {
  const {
    text,
    placeholder,
    className,
    inputClassName,
    disabled,
    readOnly,
    onChange,
    form,
    children,
    height,
    inputProps,
  }: CommonProps = props;

  let { errors, control, register, name } = props;

  if (form) {
    if (!errors) errors = form.errors;
    if (!control) control = form.control;
    if (!register) register = form.register;
  } else if (!register) {
    register = noop;
  }

  const finalRef = register.name === "register" ? register(props.val) : register;

  if (!name) {
    name = "temp_name";
  }

  const handleTextChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={"input-wrapper " + className} style={{ ...inputProps?.style }}>
      {props.type === "text" && (
        <input
          className={"form2-text " + className}
          placeholder={placeholder ? placeholder : undefined}
          autoComplete="no"
          minLength={props.minLength}
          maxLength={props.maxLength}
          value={props.value}
          defaultValue={props.defaultValue ?? undefined}
          onChange={(e) => handleTextChange(e)}
          name={name}
          ref={finalRef}
          disabled={disabled}
          readOnly={readOnly}
          onClick={props.onClick}
          {...inputProps}
        />
      )}
      {props.type === "password" && (
        <input
          type="password"
          className={"form2-text " + className}
          placeholder={placeholder ? placeholder : undefined}
          autoComplete="no"
          minLength={props.minLength}
          maxLength={props.maxLength}
          value={props.value}
          defaultValue={props.defaultValue ?? undefined}
          onChange={(e) => handleTextChange(e)}
          name={name}
          ref={finalRef}
          disabled={disabled}
          onClick={props.onClick}
          {...inputProps}
        />
      )}
      {props.type === "time" && (
        <input
          className={"form2-text " + className}
          style={{ width: "100%" }}
          type="time"
          autoComplete="no"
          value={props.value}
          defaultValue={props.defaultValue ?? undefined}
          onChange={(e) => handleTextChange(e)}
          name={name}
          ref={finalRef}
          onClick={props.onClick}
          {...inputProps}
        />
      )}
      {props.type === "number" && (
        <input
          type="number"
          className={"form2-text " + className}
          placeholder={placeholder ? placeholder : undefined}
          autoComplete="no"
          defaultValue={props.defaultValue ?? undefined}
          onChange={(e) => handleTextChange(e)}
          disabled={disabled}
          readOnly={readOnly}
          value={props.value}
          name={name}
          step={props.step}
          min={props.min}
          max={props.max}
          ref={finalRef}
          onClick={props.onClick}
          {...inputProps}
        />
      )}
      {props.type === "paragraph" && (
        <textarea
          className={"form2-paragraph " + className}
          placeholder={placeholder ? placeholder : undefined}
          autoComplete="no"
          minLength={props.minLength}
          maxLength={props.maxLength}
          defaultValue={props.defaultValue ?? undefined}
          onChange={(e) => handleTextChange(e)}
          name={name}
          ref={finalRef}
          disabled={disabled}
          readOnly={readOnly}
          onClick={props.onClick}
          rows={props.rows}
          style={{ resize: props.disableResize ? "none" : "vertical", ...props.inputStyle }}
        />
      )}
      {props.type === "checkbox" && (
        <div className={className + " checkbox-input-wrapper flex"} style={props.checkboxWrapperStyle}>
          <input
            className={className + " form2-checkbox"}
            type="checkbox"
            onChange={onChange}
            name={name}
            disabled={disabled}
            readOnly={readOnly}
            defaultChecked={props.defaultValue ? !!props.defaultValue : undefined}
            checked={props.checked}
            ref={finalRef}
            {...inputProps}
            onClick={props.onClick}
            style={{ ...props.checkboxStyle }}
          />
          <div className={"checkbox-text " + className} style={props.textStyle}>
            {children ||
              (props.tooltipText ? (
                <TextWithTooltip
                  id={name}
                  text={text}
                  tooltip={props.tooltipText}
                  place={props.tooltipPlace}
                />
              ) : (
                <span>{text}</span>
              ))}
          </div>
        </div>
      )}
      {props.type === "radio" &&
        props.options &&
        props.options.map(({ label, value, isDisabled = false }, index) => {
          return (
            <div className="checkbox-input-wrapper flex radio" key={"radio-" + label + "-" + index}>
              <input
                className={className}
                type="radio"
                onChange={onChange}
                name={name}
                disabled={isDisabled || disabled}
                readOnly={readOnly}
                value={value}
                ref={finalRef}
                defaultChecked={props.defaultValue === value}
                style={{ marginLeft: 0 }}
                {...inputProps}
              />
              <div className="checkbox-text radio-text">
                <span>{label}</span>
              </div>
            </div>
          );
        })}

      {/* For now, do not pass defaultValue within the useForm call, rather pass it directly into Formblock. There is a bug with formblocks*/}
      {props.type === "select" && control && (
        <Controller
          name={name!}
          control={control}
          defaultValue={getSelectDefaultValue(props)}
          rules={props.rules ? props.rules : props.requiredSelect ? vals.required : undefined}
          render={({ onChange, value }) => (
            <Select
              options={props.options}
              name={name!}
              onChange={(value, actionMeta) => {
                onChange(value, actionMeta);
                props.onChange?.(value);
              }}
              value={"value" in props ? props.value : value}
              height={height ? height : "32px"}
              maxMenuHeight={props.maxMenuHeight}
              minMenuHeight={props.minMenuHeight}
              isDisabled={disabled}
              defaultValue={getSelectDefaultValue(props)}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              isOptionDisabled={props.isOptionDisabled}
              width="100%"
              placeholder={placeholder}
              isMulti={false} // use "multiselect" for this.
              noOptionsMessage={({ inputValue }) =>
                !inputValue && props.noOptionsMessage ? props.noOptionsMessage : "No options"
              }
              isClearable={props.isClearable}
              styles={props.selectStyles ? props.selectStyles : props.smallSelect ? smallStyles : styles}
              filterOption={selectFilterOption}
            />
          )}
        />
      )}
      {props.type === "select" && !control && onChange && (
        <Select
          options={props.options}
          name={name!}
          onChange={(value) => {
            onChange(value);
          }}
          value={props.value}
          height={height ? height : "32px"}
          maxMenuHeight={props.maxMenuHeight}
          minMenuHeight={props.minMenuHeight}
          isDisabled={disabled}
          defaultValue={getSelectDefaultValue(props)}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          isOptionDisabled={props.isOptionDisabled}
          width="100%"
          placeholder={placeholder}
          isMulti={false} // use "multiselect" for this.
          noOptionsMessage={({ inputValue }) =>
            !inputValue && props.noOptionsMessage ? props.noOptionsMessage : "No options"
          }
          isClearable={props.isClearable}
          styles={props.selectStyles ? props.selectStyles : props.smallSelect ? smallStyles : styles}
          filterOption={selectFilterOption}
        />
      )}
      {props.type === "multiselect" && control && (
        <Controller
          name={name!}
          control={control}
          defaultValue={getMultiSelectDefaultValue(props)}
          rules={props.requiredSelect ? vals.required : undefined}
          render={({ onChange, value }) => (
            <Select
              options={props.options}
              name={name!}
              onChange={(value, actionMeta) => {
                onChange(value, actionMeta);
                props.onChange?.(value);
              }}
              value={"value" in props ? props.value : value}
              height={height ? height : "32px"}
              maxMenuHeight={props.maxMenuHeight}
              minMenuHeight={props.minMenuHeight}
              isMulti={true}
              isDisabled={disabled}
              isClearable={props.isClearable}
              defaultValue={getMultiSelectDefaultValue(props)}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              width="100%"
              placeholder={placeholder}
              styles={
                props.selectStyles
                  ? props.selectStyles
                  : props.smallSelect
                  ? smallStyles
                  : { ...styles, ...props.style }
              }
              closeMenuOnSelect={false}
            />
          )}
        />
      )}

      {props.type === "multiselect" && !control && onChange && (
        <Select
          options={props.options}
          name={name!}
          onChange={(value) => {
            props.onChange?.(value);
          }}
          value={props.value}
          height={height ? height : "32px"}
          maxMenuHeight={props.maxMenuHeight}
          minMenuHeight={props.minMenuHeight}
          isMulti={true}
          isDisabled={disabled}
          isClearable={props.isClearable}
          defaultValue={getMultiSelectDefaultValue(props)}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          width="100%"
          placeholder={placeholder}
          styles={props.smallSelect ? smallStyles : { ...styles, ...props.style }}
          closeMenuOnSelect={false}
          on
        />
      )}

      {props.type === "datetime" && control && (
        <Controller
          control={control}
          name={name!}
          defaultValue={props.defaultValue as unknown as DateTime}
          rules={props.rules}
          render={({ onChange, value }) => {
            return (
              <DateTimePicker
                value={props.value || (value as DateTime)}
                className={className}
                inputClassName={inputClassName}
                onChange={(val) => {
                  props.onChange?.(val);
                  onChange?.(val);
                }}
                dateOnly={props.dateOnly}
                timeOnly={props.timeOnly}
                min={props.min}
                max={props.max}
                sideEffect={props.sideEffect}
                disabled={props.disabled}
                placeholder={placeholder}
                timePlaceholder={props.timePlaceholder}
                dateFormat={props.dateFormat}
                timezone={props.timezone}
                isClearable={props.isClearable || (!props.rules?.required && !props.disableClearable)}
              />
            );
          }}
        />
      )}
      {props.type === "datetime" && !control && props.onChange && (
        <DateTimePicker
          value={props.value || undefined}
          className={className}
          onChange={props.onChange}
          dateOnly={props.dateOnly}
          timeOnly={props.timeOnly}
          min={props.min}
          max={props.max}
          sideEffect={props.sideEffect}
          disabled={props.disabled}
          placeholder={placeholder}
          timePlaceholder={props.timePlaceholder}
          dateFormat={props.dateFormat}
          timezone={props.timezone}
          isClearable={!props.disableClearable}
        />
      )}
      {props.type === "daterange" && (
        <Controller
          control={control}
          name={name!}
          rules={props.rules}
          defaultValue={props.value as unknown as DateRange}
          render={({ value }) => {
            return (
              <DateRangePicker
                value={value}
                className={className}
                onChange={props.onChange!}
                sideEffect={props.sideEffect}
                ignoreToText={props.ignoreToText}
                disabled={props.disabled}
                debounceMs={props.debounceMs}
              />
            );
          }}
        />
      )}
      {props.type === "monthyear" && control && (
        <Controller
          control={control}
          name={name!}
          rules={props.rules}
          defaultValue={props.value as unknown as MonthYearSelection}
          render={({ value, onChange }) => {
            return (
              <MonthYearPicker
                value={value}
                min={props.min}
                max={props.max}
                className={className}
                onChange={(val) => {
                  props.onChange?.(val);
                  onChange?.(val);
                }}
                sideEffect={props.sideEffect}
                ignoreToText={props.ignoreToText}
                disabled={props.disabled}
                debounceMs={props.debounceMs}
                placeholder={props.placeholder}
              />
            );
          }}
        />
      )}
      {props.type === "monthyear" && !control && onChange && (
        <MonthYearPicker
          value={props.value}
          min={props.min}
          max={props.max}
          className={className}
          onChange={onChange}
          sideEffect={props.sideEffect}
          ignoreToText={props.ignoreToText}
          placeholder={props.placeholder}
          disabled={props.disabled}
          debounceMs={props.debounceMs}
        />
      )}
      {props.type === "date-dropdown" && (
        <DateDropdown
          name={name!}
          control={control}
          defaultValue={props.defaultValue}
          errors={errors}
          year={props.year}
          requiredSelect={props.requiredSelect}
        />
      )}
      {props.type === "address" && (
        <AddressInput
          defaultValue={props.defaultValue}
          // @ts-expect-error AddressInput expects a more specific form of the same type (it expects the right keys, like "line1").
          errors={errors}
          showPostalName={props.showPostalName}
          name={name}
          required={props.required}
          readOnly={readOnly}
          register={register}
          notRequiredRegister={props.notRequiredRegister}
          control={control}
          shortStateSelect={props.shortStateSelect}
          autocomplete={props.autocomplete}
          setValue={props.setValue}
          value={props.value}
        />
      )}
      {props.type === "unit" && (
        <div className="form-2-unit-wrapper">
          <input
            className={"form2-text unit " + className}
            placeholder={placeholder ? placeholder : undefined}
            autoComplete="no"
            defaultValue={props.defaultValue ?? undefined}
            name={name}
            value={props.value}
            type="number"
            step={"0.01"}
            ref={finalRef}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            {...inputProps}
          />
          {props.unit && <div className={"unit-label " + className}>{props.unit}</div>}
        </div>
      )}
      {props.type === "dollars-cents" && (
        <div className="form-2-unit-wrapper">
          <Controller
            control={control}
            name={name!}
            defaultValue={props.defaultValue}
            rules={props.val || vals.isNumber}
            render={({ onChange, onBlur, value }) => (
              <ControlledInputWithCursorPosition
                className={"form2-text unit " + className}
                placeholder={placeholder ?? "0.00"}
                autoComplete="no"
                type="text"
                // https://stackoverflow.com/a/68111137/1048433
                inputMode="numeric"
                // Convert displayed dollars to cents
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(parseFloat(e.target.value) * 100)}
                // Display cents as dollars
                value={value ? (value / 100).toFixed(2) : ""}
                disabled={disabled}
                onBlur={onBlur}
                {...inputProps}
              />
            )}
          />
          <div className={"unit-label " + className}>{"$"}</div>
        </div>
      )}
      {props.type === "append" && (
        <div className="form-2-append-wrapper">
          <input
            className={"form2-text append-input " + className}
            placeholder={placeholder ? placeholder : undefined}
            autoComplete="no"
            defaultValue={props.defaultValue ?? undefined}
            onChange={(e) => handleTextChange(e)}
            name={name}
            value={props.value}
            ref={finalRef}
            min={props.min}
            max={props.max}
            disabled={disabled}
            readOnly={readOnly}
            {...inputProps}
          />
          <div className="form2-text append-label">{props.appendText}</div>
        </div>
      )}
      {props.type === "percent" && (
        <div className="form-2-unit-wrapper percent">
          <input
            className={"form2-text percent " + className}
            placeholder={placeholder ? placeholder : undefined}
            autoComplete="no"
            defaultValue={props.defaultValue ?? undefined}
            name={name}
            ref={finalRef}
            {...inputProps}
          />
          <div className={"percent-label " + className}>{"%"}</div>
        </div>
      )}
      {props.type === "phone" && (
        <Controller
          control={control}
          name={name!}
          defaultValue={props.defaultValue}
          rules={props.val}
          render={({ onChange, value }) => (
            <PhoneInput
              className={"form2-text " + className}
              defaultCountry="US"
              value={value}
              onChange={onChange}
              placeholder={props.placeholder}
              disabled={disabled}
            />
          )}
        />
      )}
      {props.type === "file" && (
        <Controller
          control={control}
          name={name!}
          defaultValue={props.defaultValue}
          rules={{
            ...props.val,
            validate: {
              ...props.val?.validate,
              ...(props.min ? { minArray: validateMinFiles(props.min) } : {}),
              // We need a custom validation that ignores deleted files when doing a required validation
              ...(props.val?.required ? { requiredArray: validateFilesRequired } : {}),
            },
          }}
          render={({ onChange: fileChange, value }) => {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            const totalOnChange = (vals: any) => {
              fileChange(vals);
              onChange?.(vals);
            };
            return (
              <FilePicker
                multiple={props.multiple}
                onChange={totalOnChange}
                files={value}
                type={props.variant!}
                maxFileSize={props.maxFileSize}
                acceptedFileTypes={props.acceptedFileTypes}
                customRenderSelectedFiles={props.customRenderSelectedFiles}
                showButton={props.showButton}
                maxFilesAllowed={props.maxFilesAllowed}
                compact={props.compact}
                showPreviewModal={props.showPreviewModal}
                label={props.dropzoneLabel}
                readOnly={props.readOnly}
                hideUploadIcon={props.hideUploadIcon}
                iconSize={props.iconSize}
                noMargin={props.noMargin}
              />
            );
          }}
        />
      )}

      {props.type === "color" && (
        <Controller
          control={control}
          name={name!}
          defaultValue={props.defaultValue}
          rules={props.val}
          render={({ onChange, value }) => <ColorPicker value={value || undefined} onChange={onChange} />}
        />
      )}

      {props.type === "ssn" && (
        <Controller
          control={control}
          name={name!}
          defaultValue={props.defaultValue}
          rules={props.val}
          render={({ onChange, value }) => (
            <InputMask
              className="form2-text ssn-input"
              onChange={(e) => onChange(e.target.value?.replaceAll("-", ""))}
              value={value}
              mask={"999-99-9999"}
              ref={finalRef}
              placeholder={placeholder}
            />
          )}
        />
      )}
      {props.type === "esignature" && (
        <Controller
          control={control}
          name={name!}
          defaultValue={props.defaultValue}
          rules={props.rules}
          render={({ onChange, value }) => (
            <ESignatureInput
              value={value}
              onChange={onChange}
              disclosure={props.disclosure}
              agreement={props.agreement}
            />
          )}
        />
      )}
      {props.type === "google-place-autocomplete" && <GooglePlaceAutocompleteInput props={props} />}
      {errors && name && get(errors, name) && <div className="error-msg">{get(errors, name)?.message}</div>}
    </div>
  );
};

export default Input;

// TODO: we should not be using {label: string, value: string} as the "value" for a "select" type form.
// Instead, we should just be using the 'value' itself,
// so that form components do not access ".value" to get the value.
const getSelectDefaultValue = (props: SelectProps) => {
  if (!(props.defaultValue != null && props.options)) return null;
  // @ts-expect-error fix me
  return props.options.find((option) => _.isEqual(option.value, props.defaultValue));
};

const getMultiSelectDefaultValue = (props: MultiSelectProps) => {
  if (props.customMultiselectDefaultValue) {
    return props.customMultiselectDefaultValue;
  }
  if (!(props.defaultValue && props.options)) return null;
  // @ts-expect-error fix me
  return props.options.filter((option) => props.defaultValue?.includes(option.value));
};

export const selectFilterOption = (option: Option<$TSFixMe>, rawInput: string): boolean => {
  if (rawInput == null) return true;
  if (option.label == null) return false;
  const normalizedInput = String(rawInput).toLowerCase().normalize();
  return String(option.label).toLowerCase().normalize().includes(normalizedInput);
};

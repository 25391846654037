import { TFunction } from "i18next";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { TeamMemberOnboardingTaskType } from "backend/services/team-member-onboarding/team-member-onboarding-types";
import { AggregatedTeamMemberOnboardingTask } from "dashboard/miter";

export const getPaymentMethodOptions = (t: TFunction): Array<{ label: string; value: string }> => [
  { label: t("Direct deposit"), value: "direct_deposit" },
  { label: t("Paper check"), value: "manual" },
];

export const getDueDateString = (startDate: string, dueDaysFromStart: number): string => {
  const startDt = DateTime.fromISO(startDate);
  const dueDateObj = startDt.plus({ days: dueDaysFromStart });
  const daysUntilDue = Math.ceil(dueDateObj.diffNow("days").days);

  if (daysUntilDue < 0) {
    return `Due ${pluralize("day", Math.abs(daysUntilDue), true)} ago`;
  } else {
    return `Due in ${pluralize("day", daysUntilDue, true)}`;
  }
};

export const getDueDate = (
  startDate: string | null | undefined,
  dueDaysFromStart: number | null | undefined
): string | null => {
  if (!startDate || dueDaysFromStart == null) {
    return null;
  }
  const startDt = DateTime.fromISO(startDate);
  const dueDateObj = startDt.plus({ days: dueDaysFromStart });
  return dueDateObj.toISODate();
};

export const taskTypeLabels: { [key in TeamMemberOnboardingTaskType]: string } = {
  personal_info: "Complete personal information",
  eeo: "Complete EEO information",
  payment_method: "Add payment method",
  bank_accounts: "Add bank accounts",
  withholdings: "Submit withholdings",
  i9_employee: "Complete I-9: Employee portion",
  i9_employer: "Complete I-9: Employer portion",
  fill_document: "Fill document",
  sign_document: "Sign document",
  form: "Complete form",
  certification: "Upload certification",
  screening: "Complete screening",
  custom_field: "Complete custom field",
  custom_task: "Complete custom task",
};

const buildTaskLabel = (prefix: string, suffix: string | undefined | null, currentLabel: string): string => {
  return suffix ? `${prefix} ${suffix}` : currentLabel;
};

const capitalizeAndJoinWords = (input: string): string => {
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * Get the label for a task
 * @param task - The task to get the label for
 * @param t - The translation function (optional)
 * @returns The label for the task
 */
export const getTaskLabel = (task: AggregatedTeamMemberOnboardingTask, t?: TFunction): string => {
  let label = t ? t(taskTypeLabels[task.type]) : taskTypeLabels[task.type];

  switch (task.type) {
    case "sign_document":
      label = buildTaskLabel(t ? t("Sign") : "Sign", task.sign_document?.label, label);
      break;
    case "fill_document":
      label = buildTaskLabel(t ? t("Fill") : "Fill", task.fillable_template?.name, label);
      break;
    case "form":
      label = buildTaskLabel(t ? t("Complete form:") : "Complete form:", task.form?.name, label);
      break;
    case "certification":
      label = buildTaskLabel(t ? t("Upload") : "Upload", task.certification?.title, label);
      break;
    case "custom_field":
      label = buildTaskLabel(
        t ? t("Complete custom field:") : "Complete custom field:",
        task.custom_field?.name,
        label
      );
      break;
    case "custom_task":
      label = buildTaskLabel(
        t ? t("Complete custom task:") : "Complete custom task:",
        task.custom_task?.title || task.title,
        label
      );
      break;
    case "screening":
      if (task.checkr_package) {
        label = capitalizeAndJoinWords(task.checkr_package);
      }
      break;
  }

  return label;
};

import { TeamPortalUserData as _TeamPortalUserData } from "./../../../backend/services/sessions/session-types";
/**************************************************************************
 * This is a utility module that wraps the Miter API into a basic module
 * that we can reuse to reduce code duplication and keep consistency when
 * calling the Miter API.
 ***************************************************************************/
import qs from "qs";
import type { MiterFilterArray, MiterQueryObject } from "../../../backend/utils/utils";
import { TablePayment } from "../../../backend/utils/aggregations/payrollAggregations";
import {
  AggregatedTimeOffRequest,
  CreateTimeOffRequestParams,
  CreateTimeOffRequestResponse,
  FrontendModel,
  TeamMember,
  TimeOffPolicy,
  UpdateTeamMemberParams,
  UpdateTimeOffRequestResponse,
  UpdateTimeoffRequestParams,
  User,
  DeleteTimeOffRequestResponse,
  RetrieveTimeOffUpdatesResponse,
  File,
  ESignatureItem,
  CustomField,
  CustomFieldValue,
  AggregatedI9,
  AggregatedFile,
  CheckDocument,
  BuildBalanceProjectionParams,
  BalanceEstimate,
  AggregatedForm,
  MiterError,
  PermissionGroup,
  AggregatedTeamMemberOnboardingChecklist,
  TeamMemberOnboardingTask,
  TeamMemberOnboardingChecklist,
} from "dashboard/miter";
import { TeamPortalUser as _TeamPortalUser } from "../../../backend/utils/aggregations/teamPortalUserAggregations";
import { FilePickerFile } from "ui/form/FilePicker";
import {
  CreateESignatureParams,
  CreateESignatureRequestParams,
  SignESignatureRequestParams,
} from "../../../backend/services/esignature-item-service";
import { SaveCustomFieldValueParams } from "../../../backend/services/custom-field-value-service";
import { CreateI9Params, UpdateI9Params } from "../../../backend/services/i-9-service";
import { FileToUpload, serialize, uploadToS3WithSignedUrl } from "miter-utils";
import { FringeContribution } from "team-portal/pages/FringesPaystubModal";
import { AggregatedOnboardingChecklistItem } from "dashboard/types/onboarding-types";
import { ChangeRequest, Department as _Department, Policy as _Policy } from "../../../backend/models";
import { ForageRequest, ForageResponse } from "../../../backend/utils/forage/forage-types";
import { AggregatedCertification, AggregatedCertificationType } from "dashboard/types/certification-types";
import { TeamMemberOverrideAttributes } from "../../../backend/services/permission-group-service";
import { S3UrlCreationParam, S3UrlCreationResponse } from "backend/services/file-service";

type Department = FrontendModel<_Department>;
type Policy = FrontendModel<_Policy>;
type TeamPortalUserData = FrontendModel<_TeamPortalUserData>;
export type TeamPortalUser = FrontendModel<_TeamPortalUser>;

type SendAuthCodeResponse = {
  success: boolean;
  method_id: string;
};

type AuthenticateResponse = {
  user: User;
  authToken?: string;
  tm: TeamPortalUser;
  team_members: TeamPortalUser[];
  onboardLink?: string;
  onboarded: boolean;
  companyHasPrgs: boolean;
  I9?: AggregatedI9;
};

type CreateFilesParams = {
  files: FileToUpload[];
};

type UpdateFilesParams = {
  files: {
    _id: string;
    label?: string | null;
  }[];
};

type FileWithURL = {
  file: File;
  url: string;
};

type DeleteFilesResponse = {
  status: "success" | "error";
  success: string[];
} & MiterError;

type DownloadFilesResponse = {
  originalname: string;
  data: Buffer;
} & MiterError;

type FileGetURLsResponse = {
  urls: Array<
    PromiseSettledResult<
      | {
          key: string;
          url: string;
          error?: undefined;
        }
      | {
          error: unknown;
          key?: undefined;
          url?: undefined;
        }
    >
  >;
  error?;
};

export type { MiterFilterArray };

export const MiterAPI = {
  policies: {
    search: async (body: MiterQueryObject): Promise<Policy[] & MiterError> => {
      return await APIHandler.request("/policies/search", "POST", body);
    },
  },
  departments: {
    search: async (body: MiterQueryObject): Promise<Department[] & MiterError> => {
      return await APIHandler.request("/departments/search", "POST", body);
    },
  },
  team_member: {
    update: async (id: string, payload: UpdateTeamMemberParams): Promise<TeamMember & MiterError> => {
      const queryPath = "/team-portal/team/" + id;
      return await APIHandler.request(queryPath, "PATCH", payload);
    },
    documents: async (
      id: string,
      filter?: MiterFilterArray
    ): Promise<{ documents: AggregatedFile[]; tax_forms: CheckDocument[] } & MiterError> => {
      const queryPath = "/team/" + id + "/documents";
      return await APIHandler.request(queryPath, "POST", { filter });
    },
    get_contributions: async (id: string, payrollId: string): Promise<FringeContribution[] & MiterError> => {
      const queryPath = "/team/" + id + "/contributions?payrollId=" + payrollId;
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_permission_groups: (
      team_member_id: string,
      overrideAttributes?: TeamMemberOverrideAttributes
    ): Promise<PermissionGroup[] & MiterError> => {
      const queryPath = "/team/" + team_member_id + "/permission-groups?" + qs.stringify(overrideAttributes);
      return APIHandler.request(queryPath, "GET");
    },
    retrieve_tax_document: async (id: string, doc_id: string): Promise<Response & MiterError> => {
      const queryPath = "/team/" + id + "/tax-document?doc_id=" + doc_id;
      return await APIHandler.request(queryPath, "GET", undefined, "application/pdf");
    },
    payments: {
      all: async (id: string): Promise<{ payments: TablePayment[] } & MiterError> => {
        const queryPath = "/team-portal/team/" + id + "/payments";
        return await APIHandler.request(queryPath, "GET");
      },
    },
    paystubs: {
      // todo remove once miter paystubs are stable
      retrieve: async (tm_id: string, payroll_ids: string[]): Promise<Response & MiterError> => {
        const queryPath = "/team-portal/team/" + tm_id + "/paystubs";
        return await APIHandler.request(queryPath, "POST", { payroll_ids });
      },
      email: async (tm_id: string, paystub_id: string): Promise<{ success?: boolean } & MiterError> => {
        return await APIHandler.request("/team-portal/team/" + tm_id + "/email-paystub", "POST", {
          paystub_id,
        });
      },
    },
    miter_paystubs: {
      retrieve: async (tm_id: string, payroll_ids: string[]): Promise<Response & MiterError> => {
        const queryPath = "/team-portal/team/" + tm_id + "/miter-paystubs";
        return await APIHandler.request(queryPath, "POST", { payroll_ids });
      },
      email: async (tm_id: string, payroll_id: string): Promise<{ success?: boolean } & MiterError> => {
        return await APIHandler.request("/team-portal/team/" + tm_id + "/email-miter-paystub", "POST", {
          payroll_id,
        });
      },
    },
    tax_forms: {
      retrieve: async (tm_id: string, doc_id: string): Promise<Response & MiterError> => {
        const queryPath = "/team-portal/team/" + tm_id + "/document?" + qs.stringify({ doc_id });
        return await APIHandler.request(queryPath, "GET");
      },
    },
    retrieve_withholdings_setup_link: async (id: string): Promise<{ url: string } & MiterError> => {
      const queryPath = "/team/" + id + "/withholdings-setup-link";
      return await APIHandler.request(queryPath, "GET");
    },
    forms: async (params: { id: string; form_id?: string }): Promise<AggregatedForm[] & MiterError> => {
      const { id, form_id } = params;
      return await APIHandler.request("/team/" + id + "/forms/", "POST", { form_id });
    },
  },
  time_off: {
    policies: {
      retrieve_many: async (filterArray: MiterFilterArray): Promise<TimeOffPolicy[] & MiterError> => {
        const queryPath = "/team-portal/time-off-policies?" + qs.stringify(filterArray);
        return await APIHandler.request(queryPath, "GET");
      },
    },
    requests: {
      retrieve: async (id: string): Promise<AggregatedTimeOffRequest & MiterError> => {
        const queryPath = "/team-portal/time-off-requests/" + id;
        return await APIHandler.request(queryPath, "GET");
      },
      retrieve_many: async (
        filterArray: MiterFilterArray
      ): Promise<AggregatedTimeOffRequest[] & MiterError> => {
        const queryPath = "/team-portal/time-off-requests?" + qs.stringify(filterArray);
        return await APIHandler.request(queryPath, "GET");
      },
      create: async (payload: CreateTimeOffRequestParams): Promise<CreateTimeOffRequestResponse> => {
        const queryPath = "/team-portal/time-off-requests/";
        return await APIHandler.request(queryPath, "POST", payload);
      },
      update: async (
        id: string,
        payload: { data: UpdateTimeoffRequestParams }
      ): Promise<UpdateTimeOffRequestResponse> => {
        const queryPath = "/team-portal/time-off-requests/" + id;
        return await APIHandler.request(queryPath, "PATCH", payload);
      },
      delete: async (id: string): Promise<DeleteTimeOffRequestResponse> => {
        const queryPath = "/team-portal/time-off-requests/" + id;
        return await APIHandler.request(queryPath, "DELETE");
      },
      getBalanceEstimate: async (params: BuildBalanceProjectionParams): Promise<BalanceEstimate> => {
        const queryPath = "/time-off-requests/balance-estimate";
        return await APIHandler.request(queryPath, "POST", params);
      },
    },
    updates: {
      retrieve_many: async (filterArray: MiterFilterArray): Promise<RetrieveTimeOffUpdatesResponse> => {
        const queryPath = "/team-portal/time-off-updates?" + qs.stringify(filterArray);
        return await APIHandler.request(queryPath, "GET");
      },
    },
  },
  users: {
    sendSmsAuthCode: async (phone: string): Promise<SendAuthCodeResponse & MiterError> => {
      const queryPath = "/users/send-auth-code";
      return await APIHandler.request(queryPath, "POST", { phone });
    },
    sendEmailAuthCode: async (email: string): Promise<SendAuthCodeResponse & MiterError> => {
      const queryPath = "/users/send-auth-code";
      return await APIHandler.request(queryPath, "POST", { email });
    },
    authenticate: async (
      method_id: string,
      auth_code: string,
      fingerprint?: string
    ): Promise<AuthenticateResponse & MiterError> => {
      const queryPath = "/users/authenticate";
      return await APIHandler.request(queryPath, "POST", {
        method_id,
        auth_code,
        app: "team-portal",
        fingerprint,
      });
    },
    sendAuthCode: async (phone: string): Promise<SendAuthCodeResponse & MiterError> => {
      const queryPath = "/users/send-auth-code";
      return await APIHandler.request(queryPath, "POST", { phone });
    },
    reverify: async (auth_code: string, method_id: string): Promise<User & MiterError> => {
      const queryPath = "/users/reverify";
      return await APIHandler.request(queryPath, "POST", { auth_code, method_id });
    },
  },
  sessions: {
    current: async (activeTM?: string): Promise<AuthenticateResponse & MiterError> => {
      const queryPath = "/sessions/current?" + qs.stringify({ app: "team-portal", new_active_tm: activeTM });
      return await APIHandler.request(queryPath, "GET");
    },
  },
  files: {
    update_many: async (body: UpdateFilesParams): Promise<CreateFilesParams & MiterError> => {
      return await APIHandler.request("/files/", "PATCH", body);
    },
    retrieve: async (id: string): Promise<FileWithURL & MiterError> => {
      return await APIHandler.request("/files/" + id, "GET");
    },
    list: async (filter: MiterFilterArray): Promise<File[] & MiterError> => {
      const queryPath = "/files?" + qs.stringify({ filter });
      return await APIHandler.request(queryPath, "GET");
    },
    delete: async (ids: string[]): Promise<DeleteFilesResponse> => {
      return await APIHandler.request("/files", "DELETE", { ids });
    },
    download: async (files: string[]): Promise<DownloadFilesResponse> => {
      return await APIHandler.request("/files/download", "POST", { files });
    },
    get_urls: async (body: { filter: MiterFilterArray }): Promise<FileGetURLsResponse> => {
      return await APIHandler.request("/files/urls", "POST", body);
    },
    /** Creates the files in the Miter DB + uploads them to S3. Returns the Miter files */
    upload: async (files: FileToUpload[]): Promise<FileWithURL[] | MiterError> => {
      if (!files.length) return [] as FileWithURL[];

      const newFiles: S3UrlCreationParam[] = files.map((file) => {
        return {
          file_name: file.originalname,
          file_mime_type: file.type,
        };
      });

      // get the signed URL from Miter
      const filesWithNewS3UrlsResponse: {
        new_files_with_urls: S3UrlCreationResponse[];
      } = await APIHandler.request("/files/s3-signed-urls", "POST", {
        new_files: newFiles,
      });

      await uploadToS3WithSignedUrl(filesWithNewS3UrlsResponse.new_files_with_urls, files);

      // remove the file blob from the body and also add aws_key
      const filesWithoutBlob = files.map((file) => {
        const { fileBlob, ...rest } = file;
        const matchingFileWithUrl = filesWithNewS3UrlsResponse.new_files_with_urls.find(
          (f) => f.file_name === file.originalname
        );
        return {
          ...rest,
          aws_key: matchingFileWithUrl?.aws_key,
          signed_url: matchingFileWithUrl?.signed_url,
        };
      });

      const res = await APIHandler.request("/files/create", "POST", { files: filesWithoutBlob });
      if (res.error) throw new Error(res.error);

      return res;
    },
    /** Marks the list of files as viewed by the team member */
    viewed: async (ids: string[]): Promise<File[] & MiterError> => {
      return await APIHandler.request("/files/viewed", "POST", { ids });
    },
  },
  esignature_items: {
    requests: {
      create: async (body: CreateESignatureRequestParams[]): Promise<ESignatureItem[] & MiterError> => {
        return await APIHandler.request("/esignature-items/requests", "POST", body);
      },
      sign: async (id: string, params: SignESignatureRequestParams): Promise<FilePickerFile & MiterError> => {
        return await APIHandler.request("/esignature-items/requests/" + id + "/sign", "POST", params);
      },
      resend: async (id: string): Promise<ESignatureItem & MiterError> => {
        return await APIHandler.request("/esignature-items/requests/" + id + "/resend", "POST");
      },
    },
    signatures: {
      create: async (body: CreateESignatureParams[]): Promise<ESignatureItem[] & MiterError> => {
        return await APIHandler.request("/esignature-items/signatures", "POST", body);
      },
    },
  },
  custom_fields: {
    search: async (filter: MiterFilterArray): Promise<CustomField[] & MiterError> => {
      return await APIHandler.request("/custom-fields/search", "POST", { filter });
    },
  },
  custom_field_values: {
    search: async (filter: MiterFilterArray): Promise<CustomFieldValue[] & MiterError> => {
      return await APIHandler.request("/custom-field-values/search", "POST", { filter });
    },
    save: async (params: SaveCustomFieldValueParams[]): Promise<CustomFieldValue[] & MiterError> => {
      return await APIHandler.request("/custom-field-values/", "POST", params);
    },
  },
  i_9s: {
    create: async (params: CreateI9Params): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s", "POST", params);
    },
    retrieve: async (id: string): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s/" + id, "GET");
    },
    retrieve_pdf: async (id: string): Promise<Response & MiterError> => {
      return await APIHandler.request("/i-9s/" + id + "/pdf", "POST");
    },
    update: async (id: string, params: UpdateI9Params): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<AggregatedI9[] & MiterError> => {
      return await APIHandler.request("/i-9s/search", "POST", { filter });
    },
  },
  onboarding_checklist_items: {
    list: async (filter: MiterFilterArray): Promise<AggregatedOnboardingChecklistItem[] & MiterError> => {
      return await APIHandler.request("/onboarding-checklist-items/list", "POST", { filter });
    },
  },
  clasp: {
    get_member_component_url: async (memberId: string): Promise<{ url: string } & MiterError> => {
      const queryPath = "/clasp/member/" + memberId + "/component-url";
      return await APIHandler.request(queryPath, "POST");
    },
    get_member_auth_token: async (
      memberId: string
    ): Promise<({ access_token: string; expires_at: string } | undefined) & MiterError> => {
      const queryPath = "/clasp/member/" + memberId + "/auth-token";
      return await APIHandler.request(queryPath, "POST");
    },
  },
  certifications: {
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedCertification>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/certifications/forage?q=" + q;
      return await APIHandler.request(queryPath, "GET");
    },
  },
  certification_types: {
    retrieve_many: async (filter: MiterFilterArray): Promise<AggregatedCertificationType[] & MiterError> => {
      return await APIHandler.request("/certification-types/retrieve-many", "POST", { filter });
    },

    retrieve: async (id: string): Promise<AggregatedCertificationType & MiterError> => {
      return await APIHandler.request("/certification-types/" + id, "GET");
    },
  },
  change_requests: {
    create: async (body: Partial<ChangeRequest>): Promise<ChangeRequest & MiterError> => {
      const queryPath = "/change-requests/";
      return await APIHandler.request(queryPath, "POST", body);
    },
  },
  mitos: {
    impersonate_user: async (user: string, authToken: string): Promise<TeamPortalUserData & MiterError> => {
      const queryPath = "/mitos/impersonate-user";
      return await APIHandler.request(queryPath, "POST", { user, app: "team-portal" }, "application/json", {
        authToken,
      });
    },
  },
  team_member_onboarding_checklists: {
    search: async (
      filter: MiterFilterArray,
      getAggregatedChecklists?: boolean
    ): Promise<AggregatedTeamMemberOnboardingChecklist[] & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists/search", "POST", {
        filter,
        getAggregatedChecklists,
      });
    },
    update_tasks: async (
      id: string,
      params: { updatedTasks: Partial<TeamMemberOnboardingTask>[] }
    ): Promise<TeamMemberOnboardingChecklist & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists/" + id + "/tasks", "PATCH", params);
    },
    complete_checklist: async (id: string): Promise<TeamMemberOnboardingChecklist & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists/" + id + "/complete", "POST");
    },
  },
};

/**************************************************************************
 * This is a basic class we built to wrap fetch into a cleaner class that
 * we could reuse above.
 ***************************************************************************/

type RequestBody = {
  [key: string]: $TSFixMe;
};

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

class APIHandler {
  static async request(
    path: string,
    method: Method,
    body: RequestBody = {},
    contentType = "application/json",
    opts?: { authToken?: string }
  ) {
    const authToken = opts?.authToken || localStorage.getItem("authToken");

    const res = await fetch(process.env.REACT_APP_MITER_BACKEND_API + path, {
      method: method,
      headers: {
        "Content-Type": contentType,
        authorization: `Bearer ${authToken}`,
        "client-service": process.env.REACT_APP_CLIENT_NAME,
      },
      ...(Object.keys(body).length > 0 ? { body: JSON.stringify(body) } : {}),
    });

    // Give the error time to show up in the Notifier.error before redirecting
    if (res.status === 401) {
      setTimeout(() => {
        window.location.pathname = "/logout";
      }, 2000);
    }

    if (this.isJSON(res)) {
      const json = await res.json();
      if (json.error) json.error_status = res.status;
      return json;
    }
    return res;
  }

  static isJSON(response) {
    const content_type = response.headers.get("content-type");
    return content_type && content_type.slice(0, 16) === "application/json";
  }
}
